import { Redirect, useHistory } from 'react-router-dom';

import { authorize, getIsAuthorized } from 'api';
import { RouteContainer } from 'components/RouteContainer';
import { Title } from 'components/Title';

export function LoginRoute() {
	const history = useHistory();

	const handleLogin = async () => {
		await authorize();
		history.push('/');
	};

	if (getIsAuthorized()) {
		return <Redirect to="/" />;
	}

	return (
		<RouteContainer>
			<Title>Login</Title>
			<button onClick={handleLogin}>Log in to Apple Music</button>
		</RouteContainer>
	);
}
