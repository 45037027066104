import theme from 'styled-theming';

export const fontFamily = `-apple-system,BlinkMacSystemFont,San Francisco,Roboto,Segoe UI,Helvetica Neue,sans-serif`;

const neutralAbsolute = {
	100: '#1f2933',
	200: '#323f4b',
	300: '#3e4c59',
	400: '#52606d',
	500: '#616e7c',
	600: '#7b8794',
	700: '#9aa5b1',
	800: '#cbd2d9',
	900: '#e4e7eb',
	1000: '#f5f7fa',
};

export const neutral = {
	1: theme('mode', {
		light: neutralAbsolute[100],
		dark: neutralAbsolute[1000],
	}),
	2: theme('mode', {
		light: neutralAbsolute[300],
		dark: neutralAbsolute[800],
	}),
	3: theme('mode', {
		light: neutralAbsolute[600],
		dark: neutralAbsolute[500],
	}),
	4: theme('mode', {
		light: neutralAbsolute[800],
		dark: neutralAbsolute[300],
	}),
	5: theme('mode', {
		light: neutralAbsolute[900],
		dark: neutralAbsolute[200],
	}),
};

export const background = theme('mode', {
	light: '#ffffff',
	dark: neutralAbsolute[100],
});
