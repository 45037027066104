import { BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'GlobalStyle';
import { MusicKitProvider } from 'components/MusicKitProvider';
import { Router } from 'components/Router';
import { ThemeProvider } from 'components/ThemeProvider';

export function App() {
	return (
		<BrowserRouter>
			<MusicKitProvider>
				<ThemeProvider>
					<GlobalStyle />
					<Router />
				</ThemeProvider>
			</MusicKitProvider>
		</BrowserRouter>
	);
}
