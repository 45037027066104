import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { unauthorize } from 'api';
import { RouteContainer } from 'components/RouteContainer';

export function LogoutRoute() {
	const history = useHistory();

	useEffect(() => {
		unauthorize().then(() => {
			// Unauthorization isn't immediate so we have to wait before redirecting
			setTimeout(() => {
				history.push('/login');
			}, 1000);
		});
	}, [history]);

	return (
		<RouteContainer>
			<p>Logging out</p>
		</RouteContainer>
	);
}
