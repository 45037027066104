import { format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import styled, { css } from 'styled-components';

import {
	getLibraryAlbum,
	getLibraryAlbums,
	getIsPlaying,
	getNowPlaying,
	getPlaybackTime,
	getPlaybackDuration,
	play,
	pause,
	skipToPreviousTrack,
	skipToNextTrack,
	setAlbum,
	setTrack,
	addPlayerEventListener,
	removePlayerEventListener,
} from 'api';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import { neutral } from 'utils/styles';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
`;

const Header = styled.header`
	display: flex;
	height: 3.5rem;
	flex-shrink: 0;
	padding: 0 1.5rem;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${neutral[4]};
`;

const HeaderSection = styled.div`
	display: flex;
`;

const Title = styled.h1`
	margin-right: 3rem;
	font-size: 1.25rem;
	font-weight: 500;
	cursor: default;
`;

const HeaderButton = styled.button<{ secondary?: boolean }>`
	padding: 0.25rem 0.625rem;
	color: ${neutral[1]};
	text-decoration: none;
	background: ${({ secondary }) => (secondary ? 'transparent' : neutral[4])};
	border: none;
	border-radius: 2rem;
	cursor: pointer;

	&:hover {
		background: ${neutral[5]};
	}

	& + & {
		margin-left: 0.5rem;
	}
`;

const SearchBar = styled.input`
	width: 30rem;
	padding: 0.5rem 0.75rem;
	background: ${neutral[5]};
	color: ${neutral[1]};
	border: none;
	border-radius: 0.25rem;
	outline: none;

	&:focus {
		background: ${neutral[4]};
		box-shadow: 0 0 0 2px ${neutral[2]};
	}
`;

const Content = styled.div`
	display: flex;
	height: calc(100% - 3.5rem);
`;

const Sidebar = styled.aside`
	display: flex;
	flex-direction: column;
	width: 20rem;
	border-right: 1px solid ${neutral[4]};
`;

const AlbumInfoSection = styled.section`
	padding: 1.5rem;
	padding-bottom: 1rem;
`;

const TrackListSection = styled.section`
	padding: 1.5rem;
	padding-top: 0;
	overflow: auto;
`;

const PlayPauseButton = styled.button`
	display: flex;
	position: relative;
	margin-bottom: 1rem;
	background: none;
	border: none;
	border-radius: 0.25rem;
	cursor: pointer;
	overflow: hidden;
`;

const NowPlayingAlbumArt = styled.img`
	width: 100%;
`;

const AlbumArtMask = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	pointer-events: none;
`;

const AlbumArtPausedText = styled.span`
	font-size: 1.25rem;
	color: white;
`;

const NowPlayingAlbumName = styled.span`
	display: block;
	font-size: 1.5rem;
	cursor: default;
`;

const NowPlayingArtistName = styled.span`
	display: block;
	margin-top: 0.5rem;
	font-size: 1rem;
	cursor: default;
`;

const NowPlayingAlbumReleaseDate = styled.span`
	display: block;
	margin-top: 0.375rem;
	font-size: 0.75rem;
	color: ${neutral[2]};
	cursor: default;
`;

const TrackList = styled.ol`
	display: flex;
	flex-direction: column;
	margin: 0 -0.5rem;
`;

const TrackItem = styled.li<{ isNowPlaying: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.5rem;
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;

	&:hover {
		background: ${neutral[5]};
	}

	${({ isNowPlaying }) =>
		isNowPlaying &&
		css`
			background: ${neutral[4]};
		`}
`;

const TrackName = styled.span`
	flex-grow: 1;
	flex-shrink: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	z-index: 1;
`;

const TrackLength = styled.span`
	color: ${neutral[2]};
	padding-left: 1rem;
	font-feature-settings: 'tnum';
	z-index: 1;
`;

const TrackPlaybackProgressBar = styled.div<{ progress: number }>`
	position: absolute;
	bottom: 0;
	left: 0;
	width: ${({ progress }) => progress * 100}%;
	height: 100%;
	background: ${neutral[3]};
`;

const Main = styled.main`
	width: calc(100% - 20rem);
`;

const AlbumList = styled(List)`
	margin: 1rem;
`;

const AlbumItem = styled.li<{ isNowPlaying: boolean }>`
	display: flex;
	align-items: center;
	padding: 0.5rem;
	border-radius: 0.25rem;
	cursor: pointer;

	&:hover {
		background: ${neutral[5]};
	}

	${({ isNowPlaying }) =>
		isNowPlaying &&
		css`
			background: ${neutral[4]};
		`}
`;

const AlbumArt = styled.img`
	width: 3rem;
	height: 3rem;
	border-radius: 0.125rem;
`;

const AlbumInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

const AlbumName = styled.span`
	margin-left: 1rem;
`;

const ArtistName = styled.span`
	margin-left: 1rem;
	font-size: 0.875rem;
	color: ${neutral[2]};
`;

export function AppRoute() {
	const [currentAlbum, setCurrentAlbum] = useState<AppleMusicApi.Album | null>(
		null
	);
	const [isPlaying, setIsPlaying] = useState(getIsPlaying());
	const [nowPlaying, setNowPlaying] = useState<MusicKit.MediaItem | null>(null);
	const [playbackTime, setPlaybackTime] = useState<number | null>(null);
	const [playbackDuration, setPlaybackDuration] = useState<number | null>(null);
	const [libraryAlbums, setLibraryAlbums] = useState<AppleMusicApi.Album[]>([]);
	const [libraryAlbumsLoading, setLibraryAlbumsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [sortBy, setSortBy] = useState<
		'date added' | 'release date' | 'artist'
	>('date added');

	const searchBarRef = useRef<HTMLInputElement>(null);

	// Load all library albums
	useEffect(() => {
		const storedLibraryAlbums = localStorage.getItem(
			LOCAL_STORAGE_KEY.LIBRARY_ALBUMS
		);
		if (storedLibraryAlbums) {
			setLibraryAlbums(JSON.parse(storedLibraryAlbums));
		} else {
			setLibraryAlbumsLoading(true);
		}

		getLibraryAlbums().then((libraryAlbums) => {
			setLibraryAlbums(libraryAlbums);
			setLibraryAlbumsLoading(false);
			localStorage.setItem(
				LOCAL_STORAGE_KEY.LIBRARY_ALBUMS,
				JSON.stringify(libraryAlbums)
			);
		});
	}, []);

	// Watch now playing song
	useEffect(() => {
		const callback = () => {
			setNowPlaying(getNowPlaying());
			console.log(getNowPlaying());
		};

		addPlayerEventListener(MusicKit.Events.mediaItemDidChange, callback);

		return () => {
			removePlayerEventListener(MusicKit.Events.mediaItemDidChange, callback);
		};
	});

	// Watch is playing state
	useEffect(() => {
		const callback = () => {
			setIsPlaying(getIsPlaying());
		};

		addPlayerEventListener(MusicKit.Events.playbackStateDidChange, callback);

		return () => {
			removePlayerEventListener(
				MusicKit.Events.playbackStateDidChange,
				callback
			);
		};
	});

	// Watch playback time
	useEffect(() => {
		const callback = () => {
			setPlaybackTime(getPlaybackTime());
		};

		addPlayerEventListener(MusicKit.Events.playbackTimeDidChange, callback);

		return () => {
			removePlayerEventListener(
				MusicKit.Events.playbackTimeDidChange,
				callback
			);
		};
	});

	// Watch playback duration
	useEffect(() => {
		const callback = () => {
			setPlaybackDuration(getPlaybackDuration());
		};

		addPlayerEventListener(MusicKit.Events.playbackDurationDidChange, callback);

		return () => {
			removePlayerEventListener(
				MusicKit.Events.playbackDurationDidChange,
				callback
			);
		};
	});

	// Listen for external media events
	useEffect(() => {
		navigator.mediaSession?.setActionHandler('previoustrack', async () => {
			await pause(); // Need to pause before switching track to prevent an error
			skipToPreviousTrack();
		});
		navigator.mediaSession?.setActionHandler('nexttrack', async () => {
			await pause(); // Need to pause before switching track to prevent an error
			skipToNextTrack();
		});
	});

	const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	const isMac = useMemo(() => {
		return navigator.platform.includes('Mac');
	}, []);

	const handleKeyDown = (event: KeyboardEvent) => {
		const ctrl = isMac ? event.metaKey : event.ctrlKey;

		switch (event.key) {
			case 'f': {
				if (ctrl) {
					event.preventDefault();
					if (searchBarRef.current) {
						searchBarRef.current.focus();
						searchBarRef.current.select();
					}
				}
				break;
			}
			case ' ': {
				event.preventDefault();
				handlePlayPause();
				break;
			}
			case 'Enter': {
				if (
					searchBarRef.current === null ||
					searchBarRef.current !== document.activeElement
				) {
					break;
				}

				event.preventDefault();
				searchBarRef.current.blur();
				break;
			}
			case 'Escape': {
				if (
					searchBarRef.current === null ||
					searchBarRef.current !== document.activeElement
				) {
					break;
				}

				event.preventDefault();
				searchBarRef.current.blur();
				break;
			}
			default: {
				break;
			}
		}
	};

	const filteredLibraryAlbums = useMemo(() => {
		return libraryAlbums.filter(
			(album) =>
				album.attributes &&
				(album.attributes.name
					.toLocaleLowerCase()
					.includes(searchValue.toLocaleLowerCase()) ||
					(album.attributes.albumName ?? '')
						.toLocaleLowerCase()
						.includes(searchValue.toLocaleLowerCase()) ||
					album.attributes.artistName
						.toLocaleLowerCase()
						.includes(searchValue.toLocaleLowerCase()))
		);
	}, [libraryAlbums, searchValue]);

	const handleSetAlbum = (album: AppleMusicApi.Album) => {
		getLibraryAlbum(album.id).then((album) => {
			setCurrentAlbum(album);
			console.log(album.relationships?.tracks.data);
		});
		setAlbum(album.id).then(() => handlePlay());
	};

	const handleSetTrack = (trackNumber: number) => {
		if (!currentAlbum) {
			return;
		}

		setTrack(currentAlbum.id, trackNumber).then(() => handlePlay());
	};

	const handlePlayPause = () => {
		if (isPlaying) {
			handlePause();
		} else {
			handlePlay();
		}
	};

	const handlePlay = () => {
		setIsPlaying(true);
		play();
	};

	const handlePause = () => {
		setIsPlaying(false);
		pause();
	};

	const formatTrackLength = (trackLength: number) => {
		const minutes = Math.floor(trackLength / 1000 / 60);
		const seconds = Math.round(trackLength / 1000) % 60;

		return `${minutes}:${String(seconds).padStart(2, '0')}`;
	};

	type AlbumWithDateAdded = AppleMusicApi.Album & {
		attributes?: { dateAdded?: string };
	};
	const sortAlbums = (a: AlbumWithDateAdded, b: AlbumWithDateAdded) => {
		switch (sortBy) {
			case 'date added': {
				return (
					(b.attributes?.dateAdded ?? '').localeCompare(
						a.attributes?.dateAdded ?? ''
					) ||
					(a.attributes?.albumName ?? '').localeCompare(
						b.attributes?.albumName ?? ''
					)
				);
			}
			case 'release date': {
				return (
					(b.attributes?.releaseDate ?? '').localeCompare(
						a.attributes?.releaseDate ?? ''
					) ||
					(a.attributes?.albumName ?? '').localeCompare(
						b.attributes?.albumName ?? ''
					)
				);
			}
			case 'artist': {
				return (
					(a.attributes?.artistName ?? '').localeCompare(
						b.attributes?.artistName ?? ''
					) ||
					(a.attributes?.releaseDate ?? '').localeCompare(
						b.attributes?.releaseDate ?? ''
					)
				);
			}
		}
	};

	type PlayParametersWithCatalogId = AppleMusicApi.PlayParameters & {
		catalogId?: string;
	};
	const isTrackNowPlaying = (track: AppleMusicApi.Song) => {
		return (
			(track.attributes?.playParams as PlayParametersWithCatalogId)
				.catalogId === nowPlaying?.id
		);
	};

	return (
		<Container>
			<Header>
				<HeaderSection>
					<Title>Music app</Title>
					<HeaderButton
						secondary={sortBy !== 'date added'}
						onClick={() => setSortBy('date added')}
					>
						Date added
					</HeaderButton>
					<HeaderButton
						secondary={sortBy !== 'release date'}
						onClick={() => setSortBy('release date')}
					>
						Release date
					</HeaderButton>
					<HeaderButton
						secondary={sortBy !== 'artist'}
						onClick={() => setSortBy('artist')}
					>
						Artist
					</HeaderButton>
				</HeaderSection>

				<HeaderSection>
					<SearchBar
						value={searchValue}
						onChange={handleChangeSearch}
						placeholder="Search ⌘F"
						ref={searchBarRef}
						autoComplete="off"
					/>
				</HeaderSection>

				<HeaderSection>
					<HeaderButton secondary as={Link} to="/logout">
						Log out
					</HeaderButton>
				</HeaderSection>
			</Header>

			<Content>
				<Sidebar>
					<AlbumInfoSection>
						{currentAlbum && (
							<>
								<PlayPauseButton onClick={handlePlayPause}>
									<NowPlayingAlbumArt
										src={
											currentAlbum.attributes?.artwork &&
											MusicKit.formatArtworkURL(
												currentAlbum.attributes.artwork,
												272,
												272
											)
										}
									/>
									{!isPlaying && (
										<AlbumArtMask>
											<AlbumArtPausedText>Paused</AlbumArtPausedText>
										</AlbumArtMask>
									)}
								</PlayPauseButton>
								<NowPlayingAlbumName>
									{currentAlbum.attributes?.name}
								</NowPlayingAlbumName>
								<NowPlayingArtistName>
									{currentAlbum.attributes?.artistName}
								</NowPlayingArtistName>
								{currentAlbum.attributes?.releaseDate && (
									<NowPlayingAlbumReleaseDate>
										{format(
											parseISO(currentAlbum.attributes.releaseDate),
											'MMMM d, yyyy'
										)}
									</NowPlayingAlbumReleaseDate>
								)}
							</>
						)}
					</AlbumInfoSection>

					<TrackListSection>
						{currentAlbum && (
							<TrackList>
								{currentAlbum.relationships?.tracks.data.map((track, index) => (
									<TrackItem
										key={track.id}
										isNowPlaying={isTrackNowPlaying(track)}
										onClick={() => handleSetTrack(index - 1)}
									>
										<TrackName>{track.attributes?.name}</TrackName>
										{track.attributes?.durationInMillis && (
											<TrackLength>
												{formatTrackLength(track.attributes.durationInMillis)}
											</TrackLength>
										)}

										{isTrackNowPlaying(track) &&
										playbackTime &&
										playbackDuration ? (
											<TrackPlaybackProgressBar
												progress={playbackTime / playbackDuration}
											/>
										) : null}
									</TrackItem>
								))}
							</TrackList>
						)}
					</TrackListSection>
				</Sidebar>

				<Main>
					{libraryAlbumsLoading ? (
						'Loading'
					) : (
						<AutoSizer>
							{({ width, height }: Size) => (
								<AlbumList
									width={width}
									height={height}
									itemCount={filteredLibraryAlbums.length}
									itemSize={64}
								>
									{({ index, style }) => {
										const album = filteredLibraryAlbums.sort(sortAlbums)[index];
										return (
											<AlbumItem
												key={album.id}
												style={style}
												onClick={() => handleSetAlbum(album)}
												isNowPlaying={
													album.attributes?.name ===
													currentAlbum?.attributes?.name
												}
											>
												{
													<AlbumArt
														src={
															album.attributes?.artwork &&
															MusicKit.formatArtworkURL(
																album.attributes.artwork,
																48,
																48
															)
														}
													/>
												}
												<AlbumInfo>
													<AlbumName>{album.attributes?.name}</AlbumName>
													<ArtistName>
														{album.attributes?.artistName}
													</ArtistName>
												</AlbumInfo>
											</AlbumItem>
										);
									}}
								</AlbumList>
							)}
						</AutoSizer>
					)}
				</Main>
			</Content>
		</Container>
	);
}
