export function authorize() {
	return MusicKit.getInstance().authorize();
}

export function unauthorize() {
	return MusicKit.getInstance().unauthorize();
}

export function getIsAuthorized() {
	return MusicKit.getInstance().isAuthorized;
}

export function setAlbum(albumId: string) {
	return MusicKit.getInstance().setQueue({ album: albumId });
}

export function setTrack(albumId: string, trackNumber: number) {
	return MusicKit.getInstance().setQueue({
		album: albumId,
		startPosition: trackNumber,
	});
}

export function play() {
	return MusicKit.getInstance().play();
}

export function pause() {
	return MusicKit.getInstance().pause();
}

export function skipToPreviousTrack() {
	return MusicKit.getInstance().skipToPreviousItem();
}

export function skipToNextTrack() {
	return MusicKit.getInstance().skipToNextItem();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addPlayerEventListener(event: string, callback: () => any) {
	return MusicKit.getInstance().player.addEventListener(event, callback);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removePlayerEventListener(event: string, callback: () => any) {
	return MusicKit.getInstance().player.removeEventListener(event, callback);
}

export async function getLibraryAlbums() {
	const limit = 100;
	let albums: AppleMusicApi.Album[] = [];
	let albumCount;
	let offset = 0;

	do {
		const albumBatch = await MusicKit.getInstance().api.library.albums(null, {
			limit,
			offset,
		});
		albums = [...albums, ...albumBatch];
		albumCount = albumBatch.length;
		offset += albumCount;
	} while (albumCount === limit);

	return albums;
}

export async function getLibraryAlbum(albumId: string) {
	return MusicKit.getInstance().api.library.album(albumId);
}

export function getNowPlaying() {
	return MusicKit.getInstance().player.nowPlayingItem;
}

export function getIsPlaying() {
	return MusicKit.getInstance().player.isPlaying;
}

export function getPlaybackTime() {
	return MusicKit.getInstance().player.currentPlaybackTime;
}

export function getPlaybackDuration() {
	return MusicKit.getInstance().player.currentPlaybackDuration;
}
