import { useEffect, useState } from 'react';

interface MusicKitProviderProps {
	children: React.ReactNode;
}

export function MusicKitProvider({ children }: MusicKitProviderProps) {
	const [configured, setConfigured] = useState(false);

	useEffect(() => {
		MusicKit.configure({
			developerToken: process.env.REACT_APP_MUSICKIT_DEVELOPER_TOKEN,
			app: {
				name: 'Music app',
				build: '0.0.1',
			},
		});

		setConfigured(true);
	}, []);

	if (!configured) {
		return null;
	}

	return <>{children}</>;
}
