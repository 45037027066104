import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { getIsAuthorized } from 'api';

export function PrivateRoute(
	props: RouteProps & {
		component?: React.ComponentType;
	}
) {
	if (!getIsAuthorized()) {
		return (
			<Redirect
				to={{
					pathname: '/login',
				}}
			/>
		);
	}

	return <Route {...props} />;
}
