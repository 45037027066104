import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components/PrivateRoute';
import { AppRoute } from 'routes/AppRoute';
import { LoginRoute } from 'routes/LoginRoute';
import { LogoutRoute } from 'routes/LogoutRoute';
import { PageNotFoundRoute } from 'routes/PageNotFoundRoute';

export function Router() {
	return (
		<Switch>
			<Route exact path="/login" component={LoginRoute} />

			<PrivateRoute exact path="/" component={AppRoute} />
			<PrivateRoute exact path="/logout" component={LogoutRoute} />

			<Route component={PageNotFoundRoute} />
		</Switch>
	);
}
