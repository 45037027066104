import { createGlobalStyle } from 'styled-components';

import { background, fontFamily, neutral } from 'utils/styles';

export const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	body {
		font-family: ${fontFamily};
		background: ${background};
		color: ${neutral[1]};
	}

	input,
	select,
	textarea,
	button {
		font-family: inherit;
		font-size: inherit;
	}

	a {
		line-height: normal;
		color: ${neutral[1]};
	}
`;
