import { RouteContainer } from 'components/RouteContainer';
import { Title } from 'components/Title';

export function PageNotFoundRoute() {
	return (
		<RouteContainer>
			<Title>Page not found</Title>
		</RouteContainer>
	);
}
